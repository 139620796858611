// **  Initial State
const initialState = {
  groundList: null,
  isLoadingBook: false,
  bookingList: null,
  isloadBookSummary: false,
  allPitchesList: null,
  isloading: false,
  bookingTypes: null,
  isLoadingWeekly: false,
  weeklyList: null,
  isLoadingDaily: false,
  dailyList: null,
  isLoadingCancel: false,
  cancelList: null,
  isLoadingPitch: false,
  allPitchList: [],
  masterBookingTypes: [],
  isLoadMasterBookingTypes: false,
  masterBookingTypeList: [],
  isLoadMasterBookingTypeList: false,
  isLoadBookingTimeSlots: false,
  bookingTimeslots: [],
  isLoadPitchTimeSlots: false,
  pitchTimeSlots: [],
  isLoadBlockBookingPitches: false,
  blockBookingPitches: [],
  isLoadBlockBookingEligibleWeekDays: false,
  blockBookingEligibleWeekDays: [],
  isLoadBlockBookingTimeSlots: false,
  blockBookingTimeSlots: [],
  isLoadBlockBookingAnotherDates: false,
  blockBookingAnotherDates: [],
  isLoadBookingSummary: false,
  bookingSummary: undefined,
  isLoadBlockBookingAountCalc: false,
  blockBookingAountCalc: undefined,
  savedCard: undefined,
  isLoadsavedCard: false,
  isLoadmarkAsPaid: false,
}

export const booking = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADER_START_BOOK':
      return {
        ...state,
        isLoadingBook: true,
      }
    case 'LOADER_STOP_BOOK':
      return {
        ...state,
        isLoadingBook: false,
      }
    case 'SET_GROUND_LIST':
      return {
        ...state,
        groundList: action.payload,
      }
    case 'START_BOOKING_SUMMARY_LOADER':
      return {
        ...state,
        isloadBookSummary: true,
      }
    case 'STOP_BOOKING_SUMMARY_LOADER':
      return {
        ...state,
        isloadBookSummary: false,
      }
    case 'GET_BOOKING_SUMMARY_LIST':
      return {
        ...state,
        bookingList: action.payload,
      }
    case 'LOADER_START_PITCHES':
      return {
        ...state,
        isloading: true,
      }
    case 'LOADER_STOP_PITCHES':
      return {
        ...state,
        isloading: false,
      }
    case 'SET_PITCHES_LIST':
      return {
        ...state,
        allPitchesList: action.payload,
      }
    case 'LOADER_START_PITCH':
      return {
        ...state,
        isLoadingPitch: true,
      }
    case 'LOADER_STOP_PITCH':
      return {
        ...state,
        isLoadingPitch: false,
      }
    case 'SET_PITCH_LIST':
      return {
        ...state,
        allPitchList: action.payload,
      }
    case 'LOADER_START_BOOKING_TYPES':
      return {
        ...state,
        isloading: true,
      }
    case 'LOADER_STOP_BOOKING_TYPES':
      return {
        ...state,
        isloading: false,
      }
    case 'SET_BOOKING_TYPES':
      return {
        ...state,
        bookingTypes: action.payload,
      }
    case 'LOADER_START_WEEKLY':
      return {
        ...state,
        isLoadingWeekly: true,
      }
    case 'LOADER_STOP_WEEKLY':
      return {
        ...state,
        isLoadingWeekly: false,
      }
    case 'SET_WEEKLY_LIST':
      return {
        ...state,
        weeklyList: action.payload,
      }
    case 'LOADER_START_DAILY':
      return {
        ...state,
        isLoadingDaily: true,
      }
    case 'LOADER_STOP_DAILY':
      return {
        ...state,
        isLoadingDaily: false,
      }
    case 'SET_DAILY_LIST':
      return {
        ...state,
        dailyList: action.payload,
      }
    case 'LOADER_START_CANCEL':
      return {
        ...state,
        isLoadingCancel: true,
      }
    case 'LOADER_STOP_CANCEL':
      return {
        ...state,
        isLoadingCancel: false,
      }
    case 'SET_CANCEL_LIST':
      return {
        ...state,
        cancelList: action.payload,
      }
    case 'CLEAR_BOOKING':
      return {
        ...state,
        ...initialState,
      }
    case 'SET_MASTER_BOOKING_TYPES':
      return {
        ...state,
        masterBookingTypes: action.payload,
      }
    case 'LOADER_START_MASTER_BOOKING_TYPES':
      return {
        ...state,
        isLoadMasterBookingTypes: true,
      }
    case 'LOADER_STOP_MASTER_BOOKING_TYPES':
      return {
        ...state,
        isLoadMasterBookingTypes: false,
      }
    case 'SET_MASTER_BOOKING_TYPE_LIST':
      return {
        ...state,
        masterBookingTypeList: action.payload,
      }
    case 'LOADER_START_MASTER_BOOKING_TYPE_LIST':
      return {
        ...state,
        isLoadMasterBookingTypeList: true,
      }
    case 'LOADER_STOP_MASTER_BOOKING_TYPE_LIST':
      return {
        ...state,
        isLoadMasterBookingTypeList: false,
      }
    case 'SET_BOOKING_TIMESLOTS':
      return {
        ...state,
        bookingTimeslots: action.payload,
      }
    case 'LOADER_START_BOOKING_TIMESLOTS':
      return {
        ...state,
        isLoadBookingTimeSlots: true,
      }
    case 'LOADER_STOP_BOOKING_TIMESLOTS':
      return {
        ...state,
        isLoadBookingTimeSlots: false,
      }
    case 'SET_PITCH_TIMESLOTS':
      return {
        ...state,
        pitchTimeSlots: action.payload,
      }
    case 'LOADER_START_PITCH_TIMESLOTS':
      return {
        ...state,
        isLoadPitchTimeSlots: true,
      }
    case 'LOADER_STOP_PITCH_TIMESLOTS':
      return {
        ...state,
        isLoadPitchTimeSlots: false,
      }
    case 'SET_BLOCK_BOOKING_PITCHES':
      return {
        ...state,
        blockBookingPitches: action.payload,
      }
    case 'LOADER_START_BLOCK_BOOKING_PITCHES':
      return {
        ...state,
        isLoadBlockBookingPitches: true,
      }
    case 'LOADER_STOP_BLOCK_BOOKING_PITCHES':
      return {
        ...state,
        isLoadBlockBookingPitches: false,
      }
    case 'SET_BLOCK_BOOKING_ELIGIBLE_WEEK_DAYS':
      return {
        ...state,
        blockBookingEligibleWeekDays: action.payload,
      }
    case 'LOADER_START_BLOCK_BOOKING_ELIGIBLE_WEEK_DAYS':
      return {
        ...state,
        isLoadBlockBookingEligibleWeekDays: true,
      }
    case 'LOADER_STOP_BLOCK_BOOKING_ELIGIBLE_WEEK_DAYS':
      return {
        ...state,
        isLoadBlockBookingEligibleWeekDays: false,
      }
    case 'SET_BLOCK_BOOKING_TIMESLOTS':
      return {
        ...state,
        blockBookingTimeSlots: action.payload,
      }
    case 'LOADER_START_BLOCK_BOOKING_TIMESLOTS':
      return {
        ...state,
        isLoadBlockBookingTimeSlots: true,
      }
    case 'LOADER_STOP_BLOCK_BOOKING_TIMESLOTS':
      return {
        ...state,
        isLoadBlockBookingTimeSlots: false,
      }
    case 'SET_BLOCK_BOOKING_ANOTHER_DATES':
      return {
        ...state,
        blockBookingAnotherDates: action.payload,
      }
    case 'LOADER_START_BLOCK_BOOKING_ANOTHER_DATES':
      return {
        ...state,
        isLoadBlockBookingAnotherDates: true,
      }
    case 'LOADER_STOP_BLOCK_BOOKING_ANOTHER_DATES':
      return {
        ...state,
        isLoadBlockBookingAnotherDates: false,
      }
    case 'SET_BOOKING_SUMMARY_DETAILS':
      return {
        ...state,
        bookingSummary: action.payload,
      }
    case 'LOADER_START_BOOKING_SUMMARY_DETAILS':
      return {
        ...state,
        isLoadBookingSummary: true,
      }
    case 'LOADER_STOP_BOOKING_SUMMARY_DETAILS':
      return {
        ...state,
        isLoadBookingSummary: false,
      }
    case 'CLEAR_BOOKING_DETAILS':
      return {
        ...state,
        bookingSummary: undefined,
      }
    case 'SET_BLOCK_BOOKING_AMOUNT_CALC_DETAILS':
      return {
        ...state,
        blockBookingAountCalc: action.payload,
      }
    case 'LOADER_START_BLOCK_BOOKING_AMOUNT_CALC':
      return {
        ...state,
        isLoadBlockBookingAountCalc: true,
      }
    case 'LOADER_STOP_BLOCK_BOOKING_AMOUNT_CALC':
      return {
        ...state,
        isLoadBlockBookingAountCalc: false,
      }
    case 'SET_SAVED_CARDS':
      return {
        ...state,
        savedCard: action.payload,
      }
    case 'SET_IS_LOADING_SAVED_CARDS_START':
      return {
        ...state,
        isLoadsavedCard: true,
      }
    case 'SET_IS_LOADING_SAVED_CARDS_STOP':
      return {
        ...state,
        isLoadsavedCard: false,
      }
    case 'PAID_LOADING_START':
      return {
        ...state,
        isLoadmarkAsPaid: true,
      }
    case 'PAID_LOADING_STOP':
      return {
        ...state,
        isLoadmarkAsPaid: false,
      }
    default:
      return state
  }
}
