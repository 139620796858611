import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/account/Login'))
const ChangePassword = React.lazy(() => import('./views/changePassword'))

const App = () => {
  const { isAuthenticated, userData } = useSelector((state) => state.auth)

  /**
   * Handling logged in user redirect
   */
  const handleLoggedInUserRedirect = () => {
    if (isAuthenticated && !userData?.is_password_changed) {
      return <ChangePassword />
    } else if (isAuthenticated) {
      return <DefaultLayout />
    } else {
      return <Navigate to={'/login'} />
    }
  }

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route
            exact
            path="/login"
            name="Login Page"
            element={isAuthenticated ? <Navigate to={'/dashboard'} /> : <Login />}
          />
          <Route path="*" name="Home" element={handleLoggedInUserRedirect()} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
