// **  Initial State
const initialState = {
  isLoading: false,
  data: [],
  allCustomers: [],
  staffUsers: [],
}

export const customers = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADER_START':
      return {
        ...state,
        isLoading: true,
      }
    case 'LOADER_STOP':
      return {
        ...state,
        isLoading: false,
      }
    case 'SET_CUSTOMERS':
      return {
        ...state,
        data: action.payload,
      }
    case 'CLEAR_CUSTOMERS':
      return {
        ...state,
        ...initialState,
      }
    case 'SET_ALL_CUSTOMERS':
      return { ...state, allCustomers: action.payload }
    case 'SET_STAFF_USERS':
      return { ...state, staffUsers: action.payload }
    default:
      return state
  }
}
