import axios from 'axios'
import { toast } from 'src/helpers/toast'
import { store } from '../redux/store'

const { dispatch } = store

export const Interceptor = (store) => {
  axios.interceptors.response.use(
    (next) => {
      return Promise.resolve(next)
    },
    (error) => {
      if (
        [
          'Your session is expired please logout and login again.',
          'No permission -- see authorization schemes',
        ].includes(error?.response?.data?.error)
      ) {
        dispatch({ type: 'LOGOUT', payload: {} })
        dispatch({ type: 'CLEAR_DASHBOARD', payload: {} })
        dispatch({ type: 'CLEAR_ADMIN', payload: {} })
        dispatch({ type: 'CLEAR_BOOKING', payload: {} })
        dispatch({ type: 'CLEAR_CUSTOMERS', payload: {} })
      }

      if (error?.response?.status === 400)
        toast(`${error?.response?.data?.error || error}`, 'error')

      return Promise.reject(error)
    },
  )
}
